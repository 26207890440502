import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {
    XFormColumn,
    XFormDataTable2,
    XFormDropdownColumn
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {Utils} from "../../Utils";
import {EnumEnum} from "../../common/enums";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {SluzbaRolaPrava} from "../../model/user/sluzba-rola-prava.entity";
import {SluzbaRola} from "../../model/user/sluzba-rola.entity";
import {Sluzba} from "../../model/user/sluzba.entity";
import {XInputFile} from "../klient/XInputFile";
import {Panel} from "primereact/panel";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

@Form("Sluzba")
export class SluzbaForm extends XFormBaseModif {

    constructor(props: any) {
        super(props);

        this.onRemoveRowRola = this.onRemoveRowRola.bind(this);
    }

    createNewObject(): XObject {
        return {sluzbaRolaList: [], version: 0};
    }

    readOnlyRola(object: Sluzba, tableRow: SluzbaRola): boolean {
        return !XUtils.isTableRowInserted(tableRow);
    }

    async onRemoveRowRola(tableRow: SluzbaRola) {
        // ak je row zapisany v DB, skontrolujeme, ci nie je referencovany json objektom v zazname SluzbaRolaPrava
        // (ostatne referencie skontroluje DB)
        if (!XUtils.isTableRowInserted(tableRow)) {
            // json ma takuto strukturu - priklad:
            // {"streetwork": {"21": true, "22": true, "24": true}, "lujzaPoradenstvo": {"2": true, "3": true}}
            // cast ([prava])::varchar konvertuje json na varchar aby bolo mozne pouzit LIKE operator
            // field prava treba uviest v zatvorkach, inac nam TypeORM neurobi replace na nazov stlpca (v tomto pripade ok, ale ked sa stlpec inac vola mame problem)
            const sluzbaRolaPravaList: SluzbaRolaPrava[] = await XUtils.fetchRows(
                'SluzbaRolaPrava',
                {where: `([prava])::VARCHAR LIKE '%"${tableRow.id}"%'`, params: {}});
            if (sluzbaRolaPravaList.length > 0) {
                alert(`Nie je dovolené vymazať záznam, lebo je referencovaný zo záznamu prístupových práv (počet záznamov ktoré referencujú tento záznam: ${sluzbaRolaPravaList.length})`);
                return;
            }
        }

        // default remove
        this.onTableRemoveRow("sluzbaRolaList", tableRow);
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Služba"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="kod" label="Kód" size={20}/>
                        <XCheckbox form={this} field="utulok" label="Útulok"/>
                        <XInputText form={this} field="nazov" label="Názov" size={30}/>
                        <XFormDataTable2 form={this} assocField="sluzbaRolaList" label="Role" onClickRemoveRow={this.onRemoveRowRola}>
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormDropdownColumn assocField="rola" displayField="name" header="Rola" filter={Utils.enumFilter(EnumEnum.rola)} sortField="enumOrder" width="16rem" readOnly={this.readOnlyRola}/>
                        </XFormDataTable2>
                        <Panel header="Docx templates" toggleable>
                            <XFormCol labelStyle={{width:'9rem'}}>
                                <XInputFile form={this} fileField="zmluvaTemplateXFile" label="Zmluva" subdir="templates"/>
                                <XInputFile form={this} fileField="predlzenieTemplateXFile" label="Predĺženie zmluvy" subdir="templates"/>
                            </XFormCol>
                        </Panel>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
